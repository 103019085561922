<template>
    <modal ref="modalAddStep" titulo="Crear paso de Actividad" adicional="Guardar" @adicional="add">
        <div class="row m-3 f-15 justify-content-center create-activity">
            <div class="col-12 mb-3">
                <p class="input-label-top">Nombre del paso</p>
                <el-input v-model="model.nombrePaso" class="w-100" size="small" />
            </div>
            <div class="col-12 mb-3">
                <p class="input-label-top">Descripción</p>
                <el-input v-model="model.descripcion" type="textarea" :autosize="{ minRows: 4 }" class="w-100" size="small" />
            </div>
            <div class="col-12 d-none">
                <p class="f-12 mb-2">Archivos adjuntos</p>
                <div class="row">
                    <div v-for="(adj,idx) in 3" :key="idx" class="col-6 mb-2">
                        <div class="br-5 bg-f5 d-flex border">
                            <p class="flex-fill tres-puntos f-11 pl-2 my-auto"> Nombre archivo </p>
                            <div class="delete-file d-middle-center br-r-4 cr-pointer">
                                <i class="icon-window-close text-white" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            model:{
                id: null,
                nombrePaso: '',
                descripcion: '',
            },
            options: [
                {
                    value: 1,
                    label: 'Option1'
                }, 
                {
                    value: 2,
                    label: 'Option2'
                }, 
                {
                    value: 3,
                    label: 'Option3'
                }, 
                {
                    value: 4,
                    label: 'Option4'
                }, 
                {
                    value: 5,
                    label: 'Option5'
                }
            ],
            adjuntos:[]

        }
    },
    methods: {
        toggle(data){
            if (data) {
                this.model.id = data.id
                this.model.nombrePaso = data.nombrePaso
                this.adjuntos = data.adjuntos
            }
            this.$refs.modalAddStep.toggle()
        },
        add(){
            if (this.model.id != null) {
                this.model = {
                    id: null,
                    nombrePaso: '',
                }
                this.$notify({ title: 'Editado', message: 'correctamente', type: 'success' })
            } else {
                this.$notify({ title: 'Creado', message: 'correctamente', type: 'success' })
            }
            this.$refs.modalAddStep.toggle()
        },
    }
}
</script>
<style lang="scss" scoped>
.create-activity{
    .delete-file{
        width: 23px;
        height: 24px;
        background-color: #707070;
    }
}
</style>
