<template>
    <modal ref="modalViewFiles" tamano="modal-xl" titulo="Archivos adjuntos en las actividades">
        <section class="m-3 view-files">
            <div class="row justify-content-center f-15 shadow29 bg-f6 py-3 mb-5">
                <div class="col-6 f-12">
                    <p class="text-general f-500">
                        Plan de mantenimiento:
                        <span class="text-5d pl-3 f-300">Plan de mantenimiento 1</span>
                    </p>
                </div>
                <div class="col-6 f-12">
                    <p class="text-general f-500">
                        Actividadad:
                        <span class="text-5d pl-3 f-300">Revisión de aislamientos</span>
                    </p>
                </div>
            </div>
            <div class="row text-general f-12 f-500 mb-3 justify-content-center">
                <div class="col-auto w-200px text-center">
                    Nombre de archivos
                </div>
                <div class="col-5 text-center">
                    Descripción
                </div>
                <div class="col-auto w-110px text-center">
                    Adjunto
                </div>
                <div class="col-auto w-110px text-center">
                    Descargar
                </div>
            </div>
            <div class="row f-12 justify-content-center">
                <div class="col-auto w-200px text-center my-auto">
                    <p class="tres-puntos">Archivo 1</p>
                </div>
                <div class="col-5 my-auto">
                    <p class="f-12 lh-15">Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero cupiditate praesentium odit laboriosam quisquam minima totam obcaecati iusto repellendus itaque?</p>
                </div>
                <div class="col-auto w-110px text-center my-auto">
                    <img src="/img/generales/mini-img.png" alt="">
                </div>
                <div class="col-auto w-110px text-center my-auto icon-option">
                    <el-tooltip content="Descargar" effect="light" visible-arrow>
                        <i class="icon-arrow-down-bold f-25 cr-pointer" />
                    </el-tooltip>
                </div>
            </div>
        </section>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            nombreArchivo: '',
            descripcion: '',
            fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
        }
    },
    methods: {
        toggle(){
            this.$refs.modalViewFiles.toggle()
        },
        save(){
            this.toggle()
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
    }
}
</script>

<style lang="scss" scoped>
.view-files{
    .bg-f6{
        background-color: #F6F6F6 ;
    }
    .w-200px{
        max-width: 200px;
        min-width: 200px;
    }
    .w-110px{
        max-width: 110px;
        min-width: 110px;
    }

}
</style>
