<template>
    <section class="calendario-ver-mantenimiento container-fluid">
        <navbar-admin icon="icon-calendar-multiselect" />
        <!-- info top new -->
        <info-top :id="infoTop.id" 
        :img="infoTop.img" 
        :equipo="infoTop.equipo" 
        :etapa="infoTop.etapa"
        :planMantenimiento="infoTop.planMantenimiento"
        :configuracion="infoTop.configuracion"
        :material="infoTop.material" />

        <titulo-divisor titulo="Planes de mantenimiento 1">
            <div class="row">
                <div class="col-auto ml-auto">
                    <div class="icon-option my-auto">
                        <el-tooltip content="Agregar actividad" placement="top" effect="light" visible-arrow>
                            <i class="icon-plus-circle f-30 cr-pointer" @click="addActivity" />
                        </el-tooltip>
                    </div>
                </div>
                <!-- filtro -->
                <div class="col-auto icon-option my-auto pl-0 my-auto">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltrosEquipo">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
            </div>
        </titulo-divisor>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-11 calendario-ver-detalle-etapas-configuracion">
                
            </div>
        </div>
         <!-- titulos -->
        <div class="row mx-0 f-14 text-general f-500 mb-3">
            <div class="col-3 text-center">
                Actividad
            </div>
            <div class="col-2 text-center">
                Cronograma
            </div>
            <div class="col-3 text-center">
                Descripción
            </div>
            <div class="col-2 text-center">
                Adjuntos
            </div>
            <div class="col-2 text-center">
                Acciones
            </div>
        </div>
        <!-- listado -->
        <section v-for="data in plansList" :key="data.id" class="list-plan mb-3">
            <div class="row mx-0 py-2 mb-2 f-12 plan shadow29 cr-pointer " @click="openPlan(data.id)">
                <div class="col-3 my-auto">
                    <p class="tres-puntos">{{ data.nombreActividad }}</p>
                </div>
                <div class="col-2 my-auto">
                    <div class="d-flex">
                        <i :class="`${data.tipoCronograma ? 'icon-calendar-clock' : 'icon-clock-time-eight-outline'} f-15 my-auto mr-1`" />
                        <div>
                            <p> {{ data.tipoCronograma ? 'Diario' : 'Horario' }} </p>
                            <p v-if="data.tipoCronograma === 1"> Cada {{ data.tiempo }} horas </p>
                            <p v-if="data.tipoCronograma === 2"> Cada {{ data.tiempo }} días </p>
                        </div>
                    </div>
                </div>
                <div class="col-3 my-auto">
                    <p class="description lh-15 text-justify overflow-hidden"> {{ data.descripcion }} </p>
                </div>
                <div class="col-2 my-auto">
                    <div class="d-middle-center">
                        <div class="adj-item position-relative cr-pointer mx-2">
                            <div class="wh-19 cant-adj rounded-circle text-white d-middle-center">1</div>
                            <img src="/img/generales/mini-pdf.png" alt="">
                        </div>
                        <div class="adj-item position-relative cr-pointer mx-2">
                            <img src="/img/generales/mini-video.png" alt="">
                        </div>
                        <div class="adj-item position-relative cr-pointer mx-2">
                            <div class="wh-19 cant-adj rounded-circle text-white d-middle-center">2</div>
                            <img src="/img/generales/mini-img.png" alt="">
                        </div>
                        <div class="adj-item position-relative cr-pointer mx-2">
                            <img src="/img/generales/mini-folder.png" alt="" @click.stop="viewListFiles">
                        </div>
                    </div>
                </div>
                <div class="col-2 text-center my-auto icon-option">
                    <el-tooltip content="Editar" effect="light" visible-arrow>
                        <i class="icon-pencil f-22 cr-pointer" @click.stop="editActivity(data)" />
                    </el-tooltip>
                    <el-tooltip content="Eliminar" effect="light" visible-arrow>
                        <i class="icon-trash-can-outline f-22 cr-pointer" @click.stop="openModalDeleteFile" />
                    </el-tooltip>
                    <i class="icon-paperclip f-22 cr-pointer" @click.stop="openModalAddFiles" />
                </div>
            </div>
            <section :ref="`sectionSteps${data.id}`" v-if="showData">
                <!-- childrens -->
                <div class="row mx-5 f-14 border-bottom-general f-500 mt-3 mb-2 position-relative">
                    <div class="col-1">
                        Posición
                    </div>
                    <div class="col-11">
                        <div class="row mx-0">
                            <div class="col-3 text-center">
                                Pasos
                            </div>
                            <div class="col-5 text-center">
                                Descripción
                            </div>
                            <div class="col-2 text-center">
                                Adjuntos
                            </div>
                            <div class="col-2 text-center">
                                Acciones
                            </div>
                        </div>
                    </div>
                    <div class="icon-option my-auto" style="position: absolute; right: -44px; top: -3px;">
                        <el-tooltip content="Agregar paso" placement="top" effect="light" visible-arrow>
                            <i class="icon-plus-circle f-30 cr-pointer" @click="addStep" />
                        </el-tooltip>
                    </div>
                </div>
                <!-- data childrens -->
                <div v-for="(child,idx) in data.steps" :key="idx" class="row mx-5 f-12 mb-3 py-2">
                    <div class="col-1 my-auto">
                        <div class="wh-26 bg-general rounded-circle d-middle-center text-white f-14 f-500 mx-auto">
                            {{ idx+1 }}
                        </div>
                    </div>
                    <div class="col-11">
                        <div class="row border mx-0 br-4 py-2">
                            <div class="col-3 my-auto">
                                <p class="tres-puntos">{{ child.nombre }}</p>
                            </div>
                            <div class="col-5 my-auto">
                                <p class="description lh-15 text-justify overflow-hidden"> {{ child.descripcion }} </p>
                            </div>
                            <div class="col-2 my-auto">
                                <div class="d-middle-center">
                                    <div class="adj-item position-relative cr-pointer mx-2">
                                        <div class="wh-19 cant-adj rounded-circle text-white d-middle-center">1</div>
                                        <img src="/img/generales/mini-pdf.png" alt="">
                                    </div>
                                    <div class="adj-item position-relative cr-pointer mx-2">
                                        <img src="/img/generales/mini-video.png" alt="">
                                    </div>
                                    <div class="adj-item position-relative cr-pointer mx-2" @click="viewImages">
                                        <div class="wh-19 cant-adj rounded-circle text-white d-middle-center">2</div>
                                        <img src="/img/generales/mini-img.png" alt="">
                                    </div>
                                    <div class="adj-item position-relative cr-pointer mx-2">
                                        <img src="/img/generales/mini-folder.png" alt="" @click.stop="viewListFiles">
                                    </div>
                                </div>
                            </div>
                            <div class="col-2 my-auto text-center icon-option">
                                <el-tooltip content="Editar" effect="light" visible-arrow>
                                    <i class="icon-pencil f-22 cr-pointer" @click="addStep(data)" />
                                </el-tooltip>
                                <el-tooltip content="Eliminar" effect="light" visible-arrow>
                                    <i class="icon-trash-can-outline f-22 cr-pointer" @click="openModalDeleteStep" />
                                </el-tooltip>
                                <i class="icon-paperclip f-22 cr-pointer" @click="openModalAddFiles" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <!-- partials  -->
        <modal-create-activity ref="openModalCreateActivity" />
        <modal-eliminar ref="openModalDeleteFile" title="Eliminar actividad" mensaje="¿Está seguro que quiere eliminar esta actividad?" @delete="deleteFile" />
        <modal-eliminar ref="openModalDeleteStep" title="Eliminar paso de mantenimiento" mensaje="¿Está seguro que quiere eliminar paso de mantenimiento?" @delete="deleteStep" />
        <modal-add-files ref="openModalAddFiles" />
        <modal-add-step ref="openModalAddStep" />
        <modal-list-files ref="openModalListFiles" />
        <modal-filtro-lateral ref="modalFiltroEquipos" />
    </section>
</template>

<script>
import infoTop from './components/infoTop.vue'
import modalFiltroLateral from './partials/modalFiltroLateral.vue'
import modalCreateActivity from './partials/modalCreateActivity.vue'
import modalAddFiles from './partials/modalAddFiles.vue'
import modalAddStep from './partials/modalAddStep.vue'
import modalListFiles from './partials/modalListFiles.vue'
export default {
    components:{
        infoTop,
        modalFiltroLateral,
        modalCreateActivity,
        modalAddFiles,
        modalAddStep,
        modalListFiles,
    },
    data() {
        return {
            searchStage: '',
            searchMaterial: '',
            activeName: 0,
            infoTop:{
                id: 1,
                img: '/img/pruebas/equipo1.png',
                equipo: 'Reactor de leche fluidizado',
                etapa: 'Efecto 1',
                planMantenimiento: 'Plan de mantenimiento',
                configuracion: 'Configuración',
                material: 'Material'
            },
            showData: false,
            plansList:[
                {
                    id: 1,
                    nombreActividad: 'Revisión esmaltado',
                    tipo: "1",
                    tipoCronograma: 1,
                    tiempo: 25,
                    descripcion: "I wouldn't try to throw myself away, If you asked me, I'd say, To be careful, my love, At death we'll leave the same, If you want me to stay, Don't keep",
                    adjuntos:[
                        {
                            id: 1,
                            tipo: 0,
                            nombre: 'Archivo 1',
                            descripcion: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi, delectus.'
                        },
                        {
                            id: 2,
                            tipo: 1,
                            nombre: 'Archivo 2',
                            descripcion: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi, delectus.'
                        },
                        {
                            id: 3,
                            tipo: 0,
                            nombre: 'Archivo 3',
                            descripcion: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi, delectus.'
                        },
                        {
                            id: 4,
                            tipo: 1,
                            nombre: 'Archivo 4',
                            descripcion: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi, delectus.'
                        },
                    ],
                    steps: [
                        {
                            id: 1,
                            nombre: 'Revisión esmaltado',
                            descripcion: "I wouldn't try to throw myself away, If you asked me, I'd say, To be careful, my love, At death we'll leave the same, If you want me to stay, Don't keep"
                        },
                        {
                            id: 2,
                            nombre: 'Revisión esmaltado 2',
                            descripcion: "I wouldn't try to throw myself away, If you asked me, I'd say, To be careful, my love, At death we'll leave the same, If you want me to stay, Don't keep"
                        },
                    ]
                },
            ]
        }
    },
    async created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'calendario.main',
            'calendario.ver.detalle',
            'calendario.ver.detalle.configuracion',
            'calendario.ver.detalle.configuracion.mantenimiento',
        ]);
    },
    methods: {
        abrirModalFiltrosEquipo(){
            this.$refs.modalFiltroEquipos.toggle()
        },
        verCotizacion(){
            //this.$router.push({ name: '' })
        },
        openPlan(id){
            this.showData = !this.showData
        },
        deleteFile(){
            console.log('delete file');
        },
        viewListFiles(){
           this.$refs.openModalListFiles.toggle()
        },
        openModalDeleteFile(){
           this.$refs.openModalDeleteFile.toggle()
        },
        openModalAddFiles(){
           this.$refs.openModalAddFiles.toggle()
        },
        addStep(){
            this.$refs.openModalAddStep.toggle()
        },
        openModalDeleteStep(){
           this.$refs.openModalDeleteStep.toggle()
        },
        deleteStep(){
            console.log('delete step');
        },
        addActivity(){
           this.$refs.openModalCreateActivity.toggle()
        },
        editActivity(data){
           this.$refs.openModalCreateActivity.toggle(data)
        },
        viewImages(){
            console.log('ver imagenes cargar modal ya creado en modales general!');
        }
    },

}
</script>

<style lang="scss" scoped>
.calendario-ver-mantenimiento{
    
    a{
        text-decoration: none !important;
        color: var(--color-5d) !important;
    }
    .description{
        min-height: 45px;
        max-height: 45px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .adj-item{
        .cant-adj{
            position: absolute;
            background-color: #B53438;
            top: -11px;
            right: -9px;
        }
    }
    .list-plan{
        .plan{
            &:hover{
                background-color: #DDDDDD;
            }

        }
        .border-bottom-general{
            border-bottom: 1px solid var(--color-general)
        }
    }
    .bg-ef{
        background: #EFEEEE;
    }
}
</style>