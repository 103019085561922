<template>
    <modal ref="modalAddFile" tamano="modal-md" titulo="Adjuntar archivos" adicional="Guardar" @adicional="save">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-auto">
                <el-upload class="upload-demo" drag
                    action="https://subir/adjuntos"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :file-list="fileList"
                    accept="image/*,.pdf"
                    >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Suelta tu archivo aquí o <em>haz clic para cargar</em></div>
                    <div slot="tip" class="el-upload__tip text-center mt-0">Puedes adjuntar archivos PNG, JPG, PDF. MOV</div>
                </el-upload>
            </div>
            <div class="col-12 my-2">
                <p class="input-label-top">Nombre del archivo</p>
                <el-input v-model="nombreArchivo" class="f-12 br-5 w-100" size="small" />
            </div>
            <div class="col-12 my-2">
                <p class="input-label-top">Comentarios</p>
                <el-input v-model="descripcion" type="textarea" :autosize="{ minRows: 3}" class="f-12 br-5 w-100" size="small" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            nombreArchivo: '',
            descripcion: '',
            fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
        }
    },
    methods: {
        toggle(){
            this.$refs.modalAddFile.toggle()
        },
        save(){
            this.toggle()
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
    }
}
</script>

<style lang="css" scoped>
</style>
