<template>
    <modal ref="modalCreateActivity" titulo="Crear actividad de mantenimiento" adicional="Guardar" @adicional="add">
        <div class="row m-3 f-15 justify-content-center create-activity">
            <div class="col-12 mb-3">
                <p class="input-label-top">Nombre de la actividad</p>
                <el-input v-model="model.nombreActividad" class="w-100" size="small" />
            </div>
            <div class="col-12 mb-3">
                <el-radio v-model="model.tipo" label="1">Calendario</el-radio>
                <el-radio v-model="model.tipo" label="2">Horario</el-radio>
            </div>
            <div v-if="model.tipo === '1'" class="col-12 mb-3">
                <p class="input-label-top">Tipo de calendario</p>
                <el-select v-model="model.tipoCronograma" placeholder="Semanal" size="small" class="w-100">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div v-if="model.tipo === '2'" class="col-12 mb-3">
                <p class="input-label-top">Unidades de tiempo</p>
                <el-input v-model="model.tiempo" placeholder="Unidades" size="small" class="w-100" />
            </div>
            <div class="col-12 mb-3">
                <p class="input-label-top">Descripción</p>
                <el-input v-model="model.descripcion" type="textarea" :autosize="{ minRows: 4 }" class="w-100" size="small" />
            </div>
            <div v-if="model.id != null" class="col-12">
                <p class="f-12 mb-2">Archivos adjuntos</p>
                <div class="row">
                    <div v-for="adj in adjuntos" :key="adj.id" class="col-6 mb-2">
                        <div class="br-5 bg-f5 d-flex border">
                            <p class="flex-fill tres-puntos f-11 pl-2 my-auto">{{ adj.nombre }}</p>
                            <div class="delete-file d-middle-center br-r-4 cr-pointer">
                                <i class="icon-window-close text-white" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            model:{
                id: null,
                nombreActividad: '',
                tipo: '1',
                tiempo: '',
                descripcion: '',
                tipoCronograma: '',
            },
            options: [
                {
                    value: 1,
                    label: 'Option1'
                }, 
                {
                    value: 2,
                    label: 'Option2'
                }, 
                {
                    value: 3,
                    label: 'Option3'
                }, 
                {
                    value: 4,
                    label: 'Option4'
                }, 
                {
                    value: 5,
                    label: 'Option5'
                }
            ],
            adjuntos:[]

        }
    },
    methods: {
        toggle(data){
            if (data) {
                this.model.id = data.id
                this.model.nombreActividad = data.nombreActividad
                this.model.tipo = data.tipo
                this.model.tiempo = data.tiempo
                this.model.descripcion = data.descripcion
                this.model.tipoCronograma = data.tipoCronograma
                this.adjuntos = data.adjuntos
            }
            this.$refs.modalCreateActivity.toggle()
        },
        add(){
            if (this.model.id != null) {
                this.model = {
                    id: null,
                    nombreActividad: '',
                    tipo: '1',
                    tiempo: '',
                    unidades: '',
                    descripcion: '',
                    tipoCronograma: '',
                }
                this.$notify({ title: 'Editado', message: 'correctamente', type: 'success' })
            } else {
                this.$notify({ title: 'Creado', message: 'correctamente', type: 'success' })
            }
            this.$refs.modalCreateActivity.toggle()
        },
    }
}
</script>
<style lang="scss" scoped>
.create-activity{
    .delete-file{
        width: 23px;
        height: 24px;
        background-color: #707070;
    }
}
</style>
